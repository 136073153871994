<style scoped>
.vue-box {
  background-color: #eee;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>

<template>
  <div class="vue-box">
    <div class="c-panel">
      <div class="c-title">支付宝单笔转账</div>
      <el-descriptions style="width: 400px" title="账户信息" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            可用余额
          </template>
          {{ alipayAccount.availableAmount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            冻结余额
          </template>
          {{ alipayAccount.freezeAmount }}
        </el-descriptions-item>

      </el-descriptions>
      <el-form size="mini" v-if="m" label-width="150px" style="text-align: left;">

        <el-form-item label="支付宝账号:">
          <el-input v-model="m.acconut" placeholder="支付宝登录时使用的账号"></el-input>
        </el-form-item>
        <el-form-item label="支付宝姓名:">
          <el-input v-model="m.name" placeholder="支付宝实名姓名"></el-input>
        </el-form-item>
        <el-form-item label="转账金额:">
          <el-input-number v-model="m.transAmonut" @change="handleChange" :min="0.1" :precision="1" :max="100000"
                           style="width: 178px"></el-input-number>
        </el-form-item>
        <el-form-item label="转出商户:" placeholder="请选择">
          <el-select v-model="m.appid" @click.native="getServerProvider()">
            <el-option v-for="item in providerlists" :key="item.appid" :label="item.mchName" :value="item.appid" />
          </el-select>
          <el-button v-show="m.appid" size="mini" style="margin-left: 5px;"
                     @click="accountQuery">余额查询
          </el-button>
        </el-form-item>
        <el-form-item label="谷歌验证码(二选一):">
          <el-input v-model="m.code" placeholder="验证码操作密码二选一"></el-input>
        </el-form-item>
        <el-form-item label="操作密码(二选一):">
          <el-input v-model="m.optPswd" placeholder="验证码操作密码二选一"></el-input>
        </el-form-item>
        <el-form-item>
          <span class="c-label">&emsp;</span>
          <el-button v-if="this.sa_admin.role != 1" type="primary" icon="el-icon-thumb" size="mini" @click="ok">转账
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data () {
    // 使用步骤
    return {
      m: {
        name: '',
        acconut: '',
        code: '',
        optPswd: '',
        appid: '',
        transAmonut: '',
      },
      p: {
        limit: 20,
        page: 1,
      },
      providerlists: [],
      alipayAccount: { availableAmount: '待查询', freezeAmount: '待查询' },
    }
  },
  methods: {
    ok: function () {
      if (this.submit_check() != 'ok') {
        return
      }
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      let self = this
      this.sa.ajax(
        '/v1/alipay/config/transfer',
        this.m,
        function () {
          self.sa.alert('转账成功', function () { })
        }.bind(this),
        defaultCfg,
      )
    },
    submit_check: function () {
      var m = this.m
      var sa = this.sa
      if (sa.isNull(m.name)) {
        return sa.error('请输入用户姓名')
      }
      if (sa.isNull(m.acconut)) {
        return sa.error('请输入支付宝登录账号')
      }
      if (m.mode == 0) {
        if (sa.isNull(m.code)) {
          return sa.error('请输入微信商户号')
        }
      }
      return 'ok'
    },
    getServerProvider: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      }
      var p = {
        mchId: this.sa_admin.mchId,
        type: [2],
        payType: [1, 3, 4, 5, 7, 8, 9, 25, 26, 28, 29],
        limit: 20,
        page: 1,
      }
      this.sa.ajax(
        '/v1/alipay/config/transferMch', p,
        function (res) {
          this.providerlists = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    accountQuery () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/alipay/config/accountQuery?appid=' + this.m.appid,
        function (res) {
          this.alipayAccount = res.data
        }.bind(this),
        defaultCfg,
      )
    },
  },
  created () {
  },
}
</script>


